import React, { useEffect, FC } from 'react'
import cn from 'classnames'
import Link from 'next/link'
import AOS from 'aos'
import Image from 'next/image'
import styles from './Goals.module.scss'
import { Categories } from '../../../interfaces/Categories'
import { imagesGoals, linesGoals } from '../../About/staticData'
import 'aos/dist/aos.css'
import useWindowDimensions from "../../../customHooks/useWindowDimension";

type GoalsProps = {
  animation?: string
  categories: Categories[]
  containerScaling?: string
}

const Goals:FC<GoalsProps> = ({ categories, animation, containerScaling }) => {

  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  const { width } = useWindowDimensions()

  return (
    <section className={cn(styles.sectionMain, styles.sectionMainDark, containerScaling)}>
      <div className={styles.sectionMainContainer}>
        <div className={styles.sectionMainHeader}>
          <div className={styles.sectionMainText}>Over 20 supplements</div>
          <div className={styles.sectionMainHeadline}>
            What can <b>we help</b> with?
          </div>
        </div>
        <div className={styles.sectionMainBody}>
          <div className={styles.cloudTags}>
            <div className={styles.cloudTagsPic}>
              <span className={styles.cloudTagsImg}>
                <Image
                  src="/images/aboutUs/cloud-pic1.png"
                  alt="cloud-pic"
                  objectFit="contain"
                  width={465}
                  height={465}
                />
              </span>
            </div>
            {width > 1024 ?
            <div className={styles.cloudTagsGroup}>
              <ul className={cn(styles.cloudTagsList, styles.lineListFirst)}>
                {imagesGoals.slice(0, 5).map((item: any) => {
                  return (
                      <>
                        <li className={styles.cloudTagsItem}>
                          <Link
                              href={item.link}>
                            <a>
                              <div className={styles.cloudTagsLink}>
                                <Image
                                    className={styles.icon}
                                    src={item.src}
                                    alt={item.title}
                                    objectFit="contain"
                                    width={34}
                                    height={34}
                                />
                                <span>{item.title}</span>
                              </div>
                              {linesGoals.map((line) => {
                                return (
                                    <React.Fragment key={line.title}>
                                      {item.title === line.title && (
                                          <span className={styles.line}></span>
                                      )}
                                    </React.Fragment>
                                )
                              })}
                            </a>
                          </Link>
                        </li>
                      </>
                  )
                })}
              </ul>
              <ul className={cn(styles.cloudTagsList, styles.lineListSecond)}>
                {imagesGoals.slice(5, 10).map((item: any) => {
                  return (
                      <>
                        <li className={styles.cloudTagsItem}>
                          <Link
                              href={item.link}>
                            <a>
                              <div className={styles.cloudTagsLink}>
                                <Image
                                   className={styles.icon}
                                   src={item.src}
                                   alt={item.title}
                                   objectFit="contain"
                                   width={34}
                                   height={34}
                                />
                                <span>{item.title}</span>
                              </div>
                              {linesGoals.map((line) => {
                                return (
                                 <React.Fragment key={line.title}>
                                     {item.title === line.title && (
                                     <span className={styles.line}></span>
                                     )}
                                 </React.Fragment>
                                )
                              })}
                            </a>
                          </Link>
                        </li>
                      </>
                  )
                })}
              </ul>
            </div>
                :
                <div className={styles.cloudTagsGroup}>
                  <ul className={cn(styles.cloudTagsList, styles.lineListFirst)}>
                    {imagesGoals.slice(0, 2).map((item: any) => {
                      return (
                          <>
                            <li className={styles.cloudTagsItem}>
                              <Link
                                  href={item.link}>
                                <a className={styles.cloudTagsLink}>
                                  <Image
                                      className={styles.icon}
                                      src={item.src}
                                      alt={item.title}
                                      objectFit="contain"
                                      width={34}
                                      height={34}
                                  />
                                  <span>{item.title}</span>
                                  {linesGoals.map((line) => {
                                    return (
                                        <React.Fragment key={line.title}>
                                          {item.title === line.title && (
                                              <span className={styles.line}></span>
                                          )}
                                        </React.Fragment>
                                    )
                                  })}
                                </a>
                              </Link>
                            </li>
                          </>
                      )
                    })}
                  </ul>
                  <ul className={cn(styles.cloudTagsList, styles.lineListFirst)}>
                    {imagesGoals.slice(2, 4).map((item: any) => {
                      return (
                          <>
                            <li className={styles.cloudTagsItem}>
                              <Link
                                  href={item.link}>
                                <a className={styles.cloudTagsLink}>
                                  <Image
                                      className={styles.icon}
                                      src={item.src}
                                      alt={item.title}
                                      objectFit="contain"
                                      width={34}
                                      height={34}
                                  />
                                  <span>{item.title}</span>
                                  {linesGoals.map((line) => {
                                    return (
                                        <React.Fragment key={line.title}>
                                          {item.title === line.title && (
                                              <span className={styles.line}></span>
                                          )}
                                        </React.Fragment>
                                    )
                                  })}
                                </a>
                              </Link>
                            </li>
                          </>
                      )
                    })}
                  </ul>
                  <ul className={cn(styles.cloudTagsList, styles.lineListSecond)}>
                    {imagesGoals.slice(4, 7).map((item: any) => {
                      return (
                          <>
                            <li className={styles.cloudTagsItem}>
                              <Link
                                  href={item.link}>
                                <a className={styles.cloudTagsLink}>
                                  <Image
                                      className={styles.icon}
                                      src={item.src}
                                      alt={item.title}
                                      objectFit="contain"
                                      width={34}
                                      height={34}
                                  />
                                  <span>{item.title}</span>
                                  {linesGoals.map((line) => {
                                    return (
                                        <React.Fragment key={line.title}>
                                          {item.title === line.title && (
                                              <span className={styles.line}></span>
                                          )}
                                        </React.Fragment>
                                    )
                                  })}
                                </a>
                              </Link>
                            </li>
                          </>
                      )
                    })}
                  </ul>
                  <ul className={cn(styles.cloudTagsList, styles.lineListSecond)}>
                    {imagesGoals.slice(7, 10).map((item: any) => {
                      return (
                          <>
                            <li className={styles.cloudTagsItem}>
                              <Link
                                  href={item.link}>
                                <a className={styles.cloudTagsLink}>
                                  <Image
                                      className={styles.icon}
                                      src={item.src}
                                      alt={item.title}
                                      objectFit="contain"
                                      width={34}
                                      height={34}
                                  />
                                  <span>{item.title}</span>
                                  {linesGoals.map((line) => {
                                    return (
                                        <React.Fragment key={line.title}>
                                          {item.title === line.title && (
                                              <span className={styles.line}></span>
                                          )}
                                        </React.Fragment>
                                    )
                                  })}
                                </a>
                              </Link>
                            </li>
                          </>
                      )
                    })}
                  </ul>
                </div>
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Goals
