export const tickerData = [
  {
    src: '/images/aboutUs/skin.svg',
    title: 'Skin',
  },
  {
    src: '/images/aboutUs/icon.svg',
    title: 'Hair',
  },
  {
    src: '/images/aboutUs/nails.svg',
    title: 'Nails',
  },
  {
    src: '/images/aboutUs/iconT.svg',
    title: 'Teeth',
  },
]

export const photoData = [
  {
    id: 1,
    src: '/images/aboutUs/hero1.jpg',
  },
  {
    id: 2,
    src: '/images/aboutUs/hero2.jpg',
  },
  {
    id: 3,
    src: '/images/aboutUs/hero3.jpg',
  },
]

export const selectedSkus = [
  'VID3-VI',
  'VITC-VI',
  'JOIN-VI',
  'TURM-VO',
  'CORA-VI',
  'ZINC-VI',
  'STRE-VI',
  'OMEG-VO',
  'SLEE-VO',
  'COQ1-VI',
  'VISI-VO',
  'MAGN-VI',
  'BIOT-VO',
  'D_VID3-VI',
  'D_VITC-VI',
  'D_JOIN-VI',
  'D_TURM-VO',
  'D_CORA-VI',
  'D_ZINC-VI',
  'D_STRE-VI',
  'D_OMEG-VO',
  'D_SLEE-VO',
  'D_COQ1-VI',
  'D_VISI-VO',
  'D_MAGN-VI',
  'D_BIOT-VO',
]

export const imagesGoals = [
  {
    title: 'Well-Being',
    src: '/images/Goals/goals2.svg',
    link: 'https://shop.vitaliv.us/collections/well-being'
  },
  {
    title: 'Bone & Joint health',
    src: '/images/Goals/goals5.svg',
    link: 'https://shop.vitaliv.us/collections/bone-joints-health'
  },
  {
    title: 'Cardiovascular health',
    src: '/images/Goals/goals6.svg',
    link: 'https://shop.vitaliv.us/collections/cardiovascular-health'
  },
  {
    title: 'Stress/Mood/Sleep',
    src: '/images/Goals/goals7.svg',
    link: 'https://shop.vitaliv.us/collections/stress-mood-sleep'
  },
  {
    title: 'Digestion',
    src: '/images/Goals/goals10.svg',
    link: 'https://shop.vitaliv.us/collections/digestion-weight-management'
  },
  {
    title: 'Weight management',
    src: '/images/Goals/goals4.svg',
    link: 'https://shop.vitaliv.us/collections/weight-management-1'
  },
  {
    title: 'Hair, Skin & Nails',
    src: '/images/Goals/goals1.svg',
    link: 'https://shop.vitaliv.us/collections/hair-skin-nails'
  },
  {
    title: 'Eye health',
    src: '/images/Goals/goals8.svg',
    link: 'https://shop.vitaliv.us/collections/eye-health'
  },
  {
    title: "Men's health",
    src: '/images/Goals/goals9.svg',
    link: 'https://shop.vitaliv.us/collections/mens-health'
  },
  {
    title: "Women's health",
    src: '/images/Goals/goals3.svg',
    link: 'https://shop.vitaliv.us/collections/womens-health'
  },
]

export const linesGoals = [
  {
    title: 'Well-Being',
    duration: 3000,
  },
  {
    title: 'Bone & Joint health',
    duration: 2700,
  },
  {
    title: 'Cardiovascular health',
    duration: 2400,
  },
  {
    title: 'Stress/Mood/Sleep',
    duration: 2100,
  },
  {
    title: 'Digestion',
    duration: 1800
  },
  {
    title: 'Weight management',
    duration: 300,
  },
  {
    title: 'Hair, Skin & Nails',
    duration: 600,
  },
  {
    title: 'Eye health',
    duration: 900,
  },
  {
    title: "Men's health",
    duration: 1200,
  },
  {
    title: "Women's health",
    duration: 1500,
  },
]

export const storyData = [
  {
    id: 0,
    text: 'The history of Vitaliv began in 2014, in ',
    text2: 'Norway',
    text3: 'and came from the objective of promoting and maintaining both our health and that of our loved ones.',
    src: '/images/aboutUs/story1.png'
  },
  {
    id: 1,
    text: 'We did a deep dive into how nutrition and lifestyle influence our',
    text2: 'well-being',
    text3: 'and how to use this to achieve positive health goals.',
    src: '/images/aboutUs/story2.png'
  }
]